








































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VueCropper from 'vue-cropperjs';
import SearchSVG from '@/assets/img/search.svg';
import axios from 'axios';
import { UnsplashPhoto, UnsplashResponse } from '@/types/unsplash';
import { handleCroppedImage } from '@/helpers/lottie';
import CrossSVG from '@/assets/img/close.svg';
@Component({ components: { VueCropper, CrossSVG, SearchSVG } })
export default class FreeStockPicker extends Vue {
  @Prop({}) private data!: any;
  @Prop({}) confirm!: any;
  private search = '';
  private debounce: any = null;
  private debounceTime = 1000;
  private results: Array<UnsplashPhoto> = [];
  private updateSearch(e: any) {
    this.search = e.target.value;
    clearTimeout(this.debounce);
    this.debounce = setTimeout(async () => {
      await this.searchImages(this.search);
    }, this.debounceTime);
  }
  @Watch('confirm')
  private handleConfirm() {
    this.$modal.$emit('confirm', 'confirmed');
  }
  private async searchImages(query: string | null) {
    let handler: { data: UnsplashResponse | Array<UnsplashPhoto> } | null;
    if (!query) {
      // Get random image
      // Hardcoded as it's needed to be done on backend
      handler = await axios.get(
        `https://api.unsplash.com/photos/random?count=30&client_id=A1o2bPNYy6NdM5CRvOMZ3e54j4aRWMtVmFAvKVQF8c4`
      );
    } else {
      handler = await axios.get(
        `https://api.unsplash.com/search/photos?page=1&per_page=30&query=${query}&client_id=A1o2bPNYy6NdM5CRvOMZ3e54j4aRWMtVmFAvKVQF8c4`
      );
    }

    if (handler && handler.data) {
      //@ts-ignore
      this.results = handler.data.results ? handler.data.results : handler.data;
    }
    return [];
  }
  private loadImage(image: UnsplashPhoto) {
    this.$modal.load({
      template: 'Cropper',
      data: {
        img: image.urls.regular,
        ratio: this.data.ratio
      },
      onConfirm: (img: string) => {
        handleCroppedImage(img, this.data.index, () => {
          this.$forceUpdate();
          this.$modal.close();
          this.data.play();
        });
      }
    });
  }
  mounted() {
    this.searchImages(null);
  }
}
